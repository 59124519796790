<template>
  <div class="login-container">
    <div class="loginbox">
      <div class="loginlogo">
        <img class="logo1" src="/pic/logo1.png" alt="">
      </div>
      <div class="loginnav">
        <div :class="{ 'itemmenu': true, 'currentmenu': item.isSelect }" v-for="item in loginnav" @click="toUrl(item)"
          :key="item.id">
          {{ item.name }}</div>
      </div>
    </div>

    <div class="ucentermenu">
      <ucenter-menu></ucenter-menu>
    </div>
  </div>
</template>

<script>
import ucenterMenu from '@/components/login/ucenterMenu.vue'
import { loginnav } from '@/api/member'
export default {
  data() {
    return {
      loginnav: []
    };
  },
  components: {
    ucenterMenu
  },
  // computed: {
  //   loginnav: {
  //     get() {
  //       return this.$store.state.loginnav
  //     }
  //   }
  // },

  mounted() {
    this.getLoginNav()
  },

  methods: {
    toUrl(item) {
      window.location.href = item.url
    },

    getLoginNav() {
      loginnav().then(res => {
        this.loginnav = res.data
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.ucentermenu {
  height: 60px;
  width: 110px;
  position: absolute;
  right: calc(10% - 110px);
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container {
  background: #fff;
  width: 100%;
  background: #fff;
  height: 60px;
  position: relative;

  .loginbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    margin: 0 10%;
    display: flex;

    .loginlogo {
      height: 55px;
      width: 196px;

      .logo1 {
        width: 196px;
        height: 55px;
      }
    }

    .loginnav {
      display: flex;

      .itemmenu {
        width: 100px;
        height: 60px;
        line-height: 60px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;

      }

      .currentmenu {
        color: #FFFFFF;
        background: #28A7E1;
      }
    }
  }
}
</style>